export type SearchAvailableVillaInACityRequest = {
    startDate?: string;
    endDate?: string;
    cityId?: number;
    provinceId?: number;
};

export type SearchAvailableVillaInACityResponse = {
    citySearchToken: string;
    expireAt?: string;
};

export enum SortTypeCitySearchResult {
    ASC = 'ASC',
    DESC = 'DESC',
}

export enum SortPropertyVillaSearchResult {
    RATING = 'RATING',
    PRICE = 'PRICE',
    REVIEWSCORE = 'REVIEW_SCORE',
}

export type GetVillaSearchResultRequest = {
    citySearchToken: string;
    page: number;
    perPage: number;
    facilities?: string[];
    priceMin?: number;
    priceMax?: number;
    capacityMin?: number;
    capacityMax?: number;
    bedRoomMin?: number;
    bedRoomMax?: number;
    bedCountMin?: number;
    bedCountMax?: number;
    ratingMin?: number;
    ratingMax?: number;
    sortProperty?: SortPropertyVillaSearchResult;
    sortType?: SortTypeCitySearchResult;
};

export type GetVillaSearchResultResponse = {
    result: ResultSearchVillaDataType[];
    count: number;
    page: number;
    perPage: number;
};

export type VillaProvinceType = {
    id: number;
    name: string;
    nameEn: string;
    nameAr: string;
    provinceCode: string;
    countryId: number;
    country: {
        id: number;
        name: string;
        nameEn: string;
        nameAr: string;
        countryCode: string;
    };
};

export type VillaCityType = {
    id: number;
    name: string;
    nameEn: string;
    nameAr: string;
    cityCode: string;
    provinceId: number;
    province: VillaProvinceType;
};

export type VillaSearchCityType = {
    city: Array<VillaCityType>;
    province: Array<VillaProvinceType>;
};

export type ResultSearchVillaDataType = {
    address: string;
    addressAr: string;
    addressEn: string;
    bedCount: number;
    bedRoom: number;
    checkIn: string;
    checkOut: string;
    city: VillaCityType;
    cityId: number;
    description: string;
    descriptionAr: string;
    descriptionEn: string;
    descriptionTitle: string;
    descriptionTitleAr: string;
    descriptionTitleEn: string;
    extraPersonCapacity: number;
    extraPersonPrice: number;
    fullTimeReception: boolean;
    galleryImagesUrl: string[];
    hostId: number;
    hostImageUrl: string;
    hostName: string;
    hostPhoneNumber: string;
    hostResponseTime: number;
    hostStatus: number;
    id: number;
    isActive: boolean;
    isInstantBook: boolean;
    isNew: boolean;
    isPrimeRoom: boolean;
    lastModified: string;
    latitude: string;
    longitude: string;
    maxNights: number;
    minNights: number;
    personCapacity: number;
    pointAverage: number;
    pointCount: number;
    primeRoomFeatures: any;
    rate: number;
    roomCancelationId: number;
    roomId: number;
    roomName: string;
    roomNameAr: string;
    roomNameEn: string;
    roomStatus: string;
    roomTag: any;
    roomType: VillaRoomType;
    roomTypeId: number;
    totalDiscountPrice: number;
    totalPrice: number;
};

export type GetVillaSearchFiltersRequest = {
    citySearchToken: string;
};

export type GetVillaInfoRequest = {
    villaId: string;
    startDate: string;
    endDate: string;
};

export type VillaSearchFiltersType = {
    filters: {
        bedCount?: {
            min: number | null;
            max: number | null;
        };
        bedRoom?: {
            min: number | null;
            max: number | null;
        };
        capacity?: {
            min: number | null;
            max: number | null;
        };
        price?: {
            min: number | null;
            max: number | null;
        };
        rating?: {
            min: number | null;
            max: number | null;
        };
        sortProperty?: SortPropertyVillaSearchResult;
        sortType?: SortTypeCitySearchResult;
    };
};

export type VillaSearchCityRequest = {
    query: string;
    limit?: number;
};

export type VillaRoomTagType = {
    id: number;
    roomId: number;
    tags: Array<string>;
};

export type VillaRoomType = {
    id: number;
    roomTypeId: number;
    name: string;
    nameEn: string;
    nameAr: string;
    icon: string;
    description: string;
    descriptionEn: string;
    descriptionAr: string;
};

export type VillaRoomCancelationType = {
    id: number;
    cancelRuleType: string;
    cancelRuleTypeTitle: string;
    cancelRuleTypeTitleEn: string;
    cancelRuleTypeTitleAr: string;
    cancelRuleTypeDescription: string;
    cancelRuleTypeDescriptionEn: string;
    cancelRuleTypeDescriptionAr: string;
};

export type VillaRoomRuleType = {
    id: number;
    ruleId: number;
    name: string;
    nameEn: string;
    nameAr: string;
    status: boolean;
    text: string;
    icon: string;
    ruleSetType: string;
};

export type VillaRoomAttributeType = {
    description: string;
    descriptionAr: string;
    descriptionEn: string;
    id: number;
    isTopAttribute: boolean;
    name: string;
    nameAr: string;
    nameEn: string;
    value: string;
    valueAr: string;
    valueEn: string;
};

export type VillaRoomMainAttribute = {
    icon: string;
    id: number;
    name: string;
    nameAr: string;
    nameEn: string;
    attributes: VillaRoomAttributeType[];
};

export type VillaInfoType = {
    id: number;
    roomId: number;
    roomName: string;
    roomNameEn: string;
    roomNameAr: string;
    roomStatus: string;
    roomTypeId: number;
    checkIn: string;
    checkOut: string;
    fullTimeReception: boolean;
    rate: number;
    bedRoom: number;
    bedCount: number;
    latitude: string;
    longitude: string;
    cityId: number;
    address: string;
    addressEn: string;
    addressAr: string;
    isActive: boolean;
    isInstantBook: boolean;
    minNights: number;
    maxNights: number;
    lastModified: string;
    isNew: boolean;
    descriptionTitle: string;
    descriptionTitleEn: string;
    descriptionTitleAr: string;
    description: string;
    descriptionEn: string;
    descriptionAr: string;
    personCapacity: number;
    extraPersonCapacity: number;
    roomCancelationId: number;
    primeRoomFeatures: any;
    pointAverage: number;
    pointCount: number;
    hostName: string;
    hostNameAr: string;
    hostNameEn: string;
    hostId: number;
    hostImageUrl: string;
    hostStatus: number;
    hostResponseTime: number;
    hostPhoneNumber: string;
    extraPersonPrice: number;
    totalPrice: number;
    isPrimeRoom: boolean;
    city: VillaCityType;
    roomTag: VillaRoomTagType;
    roomType: VillaRoomType;
    roomCancelation: VillaRoomCancelationType;
    roomRules: Array<VillaRoomRuleType>;
    roomAttributes: Array<VillaRoomAttributeType>;
    sharedRental: {
        value: string;
        valueAr: string;
        valueEn: string;
    };
    galleryImagesUrl: string[];
    topAttributes: Array<VillaRoomMainAttribute>;
};

export type GetVillaInfoListRequest = {
    sort: string;
    filter: { property: string; value: string | boolean; rule: string }[];
    page: number;
    perPage: number;
};

export type GetVillaInfoListResponse = {
    result: VillaInfoType[];
    total: number;
    page: number;
    perPage: number;
};

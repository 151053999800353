import axios, { AxiosRequestConfig, CancelToken } from 'axios';
import { needRefreshToken } from './auth/auth';

export enum Method {
    GET = 'get',
    POST = 'post',
    PUT = 'put',
    DELETE = 'delete',
    PATCH = 'patch',
}

export type Locale = 'en' | 'ar';

// A queue for add configs to call later
const refreshAndRetryQueue: AxiosRequestConfig[] = [];
const axiosInstance = axios.create({
    baseURL: process.env.NEXT_PUBLIC_API_URL,
});
export async function Api<T>(method: Method, url: string, params: object | null, body: object | null, cancelToken?: CancelToken, token?: string | null, uploadProgress?: (progressEvent: any) => void) {
    const config: AxiosRequestConfig = {
        method,
        url: url,
        params,
        headers: token ? { Authorization: `Bearer ${token}` } : undefined,
        data: body,
        cancelToken: cancelToken,
        onUploadProgress: uploadProgress,
    };

    const resultOfCheckTokenState = await needRefreshToken();
    if (token) {
        // Check if refresh token has called
        if (resultOfCheckTokenState.state === 'waiting') {
            // add config to que to call later
            refreshAndRetryQueue.push(config);
        } else if (resultOfCheckTokenState.state === 'login') {
            // Call the Api's have added to queue
            refreshAndRetryQueue.forEach(config => {
                axiosInstance(config);
            });

            // Clear the queue
            refreshAndRetryQueue.length = 0;

            // Return the valid token
            token = resultOfCheckTokenState.token;
        }
    }

    return await axiosInstance.request<T>(config);
}

export async function ApiCMS<T>(method: Method, url: string, params: object | null, body: object | null, locale: null | string | Locale) {
    return await axios.request<T>({
        method,
        url,
        baseURL: process.env.NEXT_PUBLIC_CMS_API_URL,
        params: {
            ...params,
            locale: locale || 'en',
        },
        data: body,
    });
}
export async function ApiWp<T>(url: string, params?: object | null) {
    return await axios.request<T>({
        method: Method.GET,
        url,
        baseURL: process.env.NEXT_PUBLIC_BLOG_API_URL,
        params: {
            ...params,
        },
    });
}

import { Box, Stack, Typography } from '@mui/material';
import { useRouter } from 'next/router';
import Slider from 'react-slick';
import styles from './most-visited-city-section.module.scss';
import doha from './images/doha.png';
import beirut from './images/beirut.png';
import dubai from './images/dubai.png';
import istanbul from './images/istanbul.png';
import mashhad from './images/mashhad.png';
import paris from './images/paris.png';
import kualaLumpur from './images/kuala-lumpur.png';
import { useDispatch } from 'react-redux';
import { useMemo } from 'react';
import { toDateObject } from '../../../common/date-picker/functions';
import staticData from '../../../hotels/search-fields-hotels/components/select-hotel/static-data.json';
import { generateSearchParamsHotel } from '../../../hotels/search-fields-hotels/functions';
import { hotelSearchesSave } from '../../../../redux/hotel-searchs-slice';
import { MostVisitedCitySectionProps } from './most-visited-city-section.types';
import { HotelSearchDataType } from '../../../hotels/search-fields-hotels/search-fields-hotels.types';
import { hotelSearchClearExpirationDate, hotelSearchClearSearchToken, hotelSearchSaveSearchData } from '../../../../redux/hotel-search-slice';
import { v4 as uuidv4 } from 'uuid';
import { useLanguage } from '@travel-platform/commons/src/components/common/language/language';

const images = [
    {
        id: 31963,
        image: mashhad,
    },
    {
        id: 968,
        image: dubai,
    },
    {
        id: 924,
        image: istanbul,
    },
    {
        id: 93,
        image: paris,
    },
    {
        id: 4631,
        image: beirut,
    },
    {
        id: 458,
        image: kualaLumpur,
    },
    {
        id: 4629,
        image: doha,
    },
    {
        id: 31963,
        image: mashhad,
    },
];

export default function MostVisitedCitySection(props: MostVisitedCitySectionProps) {
    const router = useRouter();
    const dispatch = useDispatch();
    const languageJson = useLanguage();
    const slideItem: {
        data: HotelSearchDataType;
        view: {
            image: any;
        };
    }[] = useMemo(() => {
        return staticData.map(data => {
            return {
                data: {
                    hotelCity: {
                        group: 'city',
                        label: data.cityNames.find(cityName => cityName.language === router.locale)?.value || '-',
                        value: data.id.toString(),
                        place: data.country.countryNames.find(countryName => countryName.language === router.locale)?.value || '-',
                    },
                    rangeDate: {
                        start: toDateObject(new Date(new Date().setDate(new Date().getDate() + 3))),
                        end: toDateObject(new Date(new Date().setDate(new Date().getDate() + 8))),
                    },
                    roomPassenger: [
                        {
                            passengers: {
                                adult: {
                                    value: 1,
                                    askAges: false,
                                    ages: [],
                                },
                                child: {
                                    value: 0,
                                    askAges: true,
                                    ages: [],
                                },
                            },
                            id: uuidv4(),
                        },
                    ],
                },
                view: {
                    image: images.find(image => image.id === data.id)?.image || '',
                },
            };
        });
    }, [router.locale]);

    const settings = {
        slidesToShow: 4,
        infinite: true,
        speed: 500,
        autoplaySpeed: 5000,
        rtl: router.locale === 'en' ? false : true,
        autoplay: true,
        className: 'center',
        centerMode: true,
        centerPadding: '24px',
        responsive: [
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };
    const searchHotel = (item: {
        data: HotelSearchDataType;
        view: {
            image: any;
        };
    }) => {
        const params = generateSearchParamsHotel(item.data);
        dispatch(hotelSearchSaveSearchData(item.data));
        dispatch(hotelSearchClearSearchToken());
        dispatch(hotelSearchClearExpirationDate());
        dispatch(hotelSearchesSave({ data: item.data, url: `/hotels/searchresults?${params}` }));
        router.push(`/hotels/searchresults?${params}`, '', {});
    };
    return (
        <Stack spacing={{ md: 4, sx: 2 }}>
            <Typography
                sx={{
                    typography: {
                        md: 't1_bold',
                        xs: 't3_bold',
                    },
                }}>
                {languageJson.common.most_visited_hotels}
            </Typography>
            <Box className={styles.mostVisitedCitySection}>
                <Slider
                    {...settings}
                    className={styles.slider + ' sliderLanding'}>
                    {slideItem.map((slide, index) => (
                        <Typography
                            component='div'
                            className={styles.item}
                            key={index}>
                            <Box onClick={() => searchHotel(slide)}>
                                <Box
                                    className={styles.image}
                                    style={{ backgroundImage: `url(${slide.view.image.src})` }}></Box>
                                <Box className={styles.name}>
                                    <Box className={styles.text}>{slide.data.hotelCity.label}</Box>
                                </Box>
                            </Box>
                        </Typography>
                    ))}
                </Slider>
            </Box>
        </Stack>
    );
}

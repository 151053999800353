import { useRouter } from 'next/router';

export const useGetProperty = () => {
    const router = useRouter();

    const getKeyBasedOnLocale = (key: string | undefined, obj: any) => {
        if (key && obj) {
            if (typeof obj === 'object') {
                if (router.locale === 'ar' && obj[`${key}Ar`]) return obj[`${key}Ar`];
                else if (router.locale === 'en' && obj[`${key}En`]) return obj[`${key}En`];
                else return obj[key];
            } else return obj;
        } else return '';
    };

    return { getKeyBasedOnLocale };
};

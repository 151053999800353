import { Api, Method } from 'services/api';
import {
    GetVillaInfoListRequest,
    GetVillaInfoListResponse,
    GetVillaInfoRequest,
    GetVillaSearchFiltersRequest,
    GetVillaSearchResultRequest,
    GetVillaSearchResultResponse,
    SearchAvailableVillaInACityRequest,
    SearchAvailableVillaInACityResponse,
    VillaInfoType,
    VillaSearchCityRequest,
    VillaSearchCityType,
    VillaSearchFiltersType,
} from './villa.types';
import { CancelToken } from 'axios';

export const apiSearchAvailableVillaInACity = (body: SearchAvailableVillaInACityRequest | null, token?: string) => {
    return Api<SearchAvailableVillaInACityResponse>(Method.POST, '/villa/villa/search/city', null, body, undefined, token);
};

export const apiGetVillaSearchResult = (params: GetVillaSearchResultRequest, token?: string) => {
    return Api<GetVillaSearchResultResponse>(Method.GET, '/villa/villa/search/city', params, null, undefined, token);
};

export const apiGetVillaSearchFilters = (params: GetVillaSearchFiltersRequest) => {
    return Api<VillaSearchFiltersType>(Method.GET, `/villa/villa/search/city/filters`, params, null);
};

export const apiGetVillaInfo = (params: GetVillaInfoRequest) => {
    return Api<VillaInfoType>(Method.GET, `/villa/place/villa/info/`, params, null);
};

export const apiVillaSearchCity = (params: VillaSearchCityRequest | null, cancelToken: CancelToken) => {
    return Api<VillaSearchCityType>(Method.GET, '/villa/place/search/cities', params, null, cancelToken);
};

export const apiGetVillaInfoList = (params: GetVillaInfoListRequest) => {
    return Api<GetVillaInfoListResponse>(Method.GET, '/villa/place/villa/info/list', params, null);
};
